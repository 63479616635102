import { clsx } from 'clsx'

const textVariant = {
  bodySmall: 'text-body-small font-normal',
  bodyXSmall: 'text-body-x-small font-normal',
  bodyMedium: 'text-body-medium font-normal',
  bodyLarge: 'text-body-large font-normal',
  displayLarge: 'text-display-large font-medium',
  displayXLarge: 'text-display-x-large font-medium',
  display2XLarge: 'text-display-2x-large font-medium',
  display3XLarge: 'text-display-3x-large font-medium',
  headingLarge: 'text-heading-large font-bold',
  headingMedium: 'text-heading-medium font-bold',
  labelOverline: 'text-label-overline font-bold',
  labelButtonMedium: 'text-label-button-medium font-bold',
  bodySmallBold: 'text-body-small font-bold',
}

const textColor = {
  primary: 'text-content-primary',
  information: 'text-content-information',
  inverse: 'text-content-inverse',
  white: 'text-white',
  secondary: 'text-content-secondary',
}

const textAlignment = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
}

const Typography = ({
  className = '',
  variant = 'bodySmall',
  component: Tag = 'p',
  color = 'primary',
  mb = 0,
  mt = 0,
  children,
  lg = {},
  align = '',
}) => {
  return (
    <Tag
      className={clsx(
        textColor[color],
        textVariant[variant],
        `mt-${mt}`,
        `mb-${mb}`,
        align && textAlignment[align],
        lg.variant && `lg:${textVariant[lg.variant]}`,
        className
      )}
    >
      {children}
    </Tag>
  )
}

export default Typography
