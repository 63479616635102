import React from 'react'

import Rating from 'src/components/shared/Rating'
import Typography from 'src/components/shared/Typography'

import ButtonLink from 'src/components/shared/ButtonLink'

const TrustPilot = ({
  rating = 0,
  totalReviews = 0,
  outOf = 5,
  color,
  link,
  onReviewClick = () => {},
}) => (
  <div className="mt-4 flex flex-col items-center">
    <div className="mb-1 flex items-center lg:mb-2">
      <img src="/next-assets/trust-strip/trustpilot.svg" alt="trustpilot" />
      <div className="mx-2">
        <Rating
          rating={rating}
          totalStars={outOf}
          totalReviews={totalReviews}
          filledStarColor={color}
        />
      </div>
      <Typography
        className="leading-[14px]"
        variant="bodySmall"
        component="span"
      >
        {rating}/{outOf}
      </Typography>
    </div>
    <Typography variant="bodySmall" component="p">
      <strong>Excellent • </strong>
      <ButtonLink
        className="hover:underline"
        href={link}
        onClick={onReviewClick}
        target="blank"
      >
        {totalReviews}+ reviews
      </ButtonLink>
    </Typography>
  </div>
)

export default TrustPilot
